$(function() {
  if ($('.form-coupon-js').length > 0) {
    handleCouponApplying($('.form-coupon-js'));
  }
});

function handleCouponApplying(el) {
  const btnSubmit = $(el).find('.btn-submit-js');
  const btnCancel = $(el).find('.btn-cancel-js');
  const btnEditCouponCode = $('#editCouponCode');
  const collapseElement = $(el).find('.collapse');
  const couponCodeWrapper = $('#coupon-code-wrapper-js');

  btnSubmit.on('keypress click', function(e) {
    e.preventDefault();
    if (e.which === 13 || e.type === 'click') {
      let couponVal = $(el).find('#coupon').val();
      couponCodeWrapper.removeClass('d-none');
      couponCodeWrapper.find('#coupon-code-js').text(couponVal);
      collapseElement.slideUp('fast', function() {
        collapseElement.toggleClass('show');
        collapseElement.attr('style', null);
      });
    }
  });

  btnEditCouponCode.on('keypress click', function(e) {
    e.preventDefault();
    if (e.which === 13 || e.type === 'click') {
      couponCodeWrapper.addClass('d-none');
    }
  });

  btnCancel.on('click', function() {
    $(el).find('#coupon').val('');
  });
}

//export default handleCouponApplying()
