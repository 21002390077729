$(function() {
  if ($('.alert-auto-dismiss-js').length > 0) {
    handleAutoDismissAlert($('.alert-auto-dismiss-js'));
  }
});

function handleAutoDismissAlert(el) {
  $(el).delay(4000).slideUp(400, function() {
    $(this).alert('close');
  });
}

