$(function() {
  let swiperContainer = document.querySelectorAll('.swiper-container');

  initSwiperCarousel(swiperContainer);

  if ($('#courseTabs')) {
    $('#courseTabs .collapse').on('shown.bs.collapse', function() {
      initSwiperCarousel(swiperContainer);
    });
  }

  //for filters inside js.erb file
  window.globalCarouselsFunction = {};
  globalCarouselsFunction.initSwiperCarouselFromERB = (swiperContainerFromERB) => {
    initSwiperCarousel(swiperContainerFromERB);
  }
});


function initSwiperCarousel(carousels) {

  carousels.forEach(function(el) {

    new Swiper(el, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      navigation: {
        nextEl: el.parentElement.parentElement.parentElement.querySelector('.btn-next'),
        prevEl: el.parentElement.parentElement.parentElement.querySelector('.btn-prev'),
      },
    });
  });
}
