(function($) {
  $(function() {
    //Scroll To Link
    $('.scroll-to-js').on('click', function(e) {
      var pointId = $(this).attr('href');
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $(pointId).offset().top - $('.navbar.sticky-top').height() - 10
      }, 500);

      return false;
    });

    $('#backBtn').on('click', function(e) {
      e.preventDefault();
      window.history.back();
    })
    
  });
}(jQuery));
