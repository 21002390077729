$(function() {
  if ($('#datetimepicker2').length > 0) {
    var enabledDatesArrayFormatted = $('#datetimepicker2').data('enabled-dates-formatted');
    var enabledDatesArray = $('#datetimepicker2').data('enabled-dates');
    var enabledDatesArrayMoment = [];
    var enabledDatesLinksArray = $('#datetimepicker2').data('enabled-dates-links');

    $.each(enabledDatesArrayFormatted, function(i, val) {
      enabledDatesArrayMoment[i] = new Date(val);
    });


    $('#datetimepicker2').on("change.datetimepicker", ({
      date
    }) => {
      $.each(enabledDatesArrayFormatted, function(i, val) {
        var value = moment(val).format("YYYY-MM-DD");

        if (date.format("YYYY-MM-DD") == value) {
          $('.custom-datepicker #newLink').attr('href', enabledDatesLinksArray[i]);
          $('.custom-datepicker #newDate').html(enabledDatesArray[i]);
        }
      });
    });

    $('#datetimepicker2').datetimepicker({
      locale: 'en-gb',
      format: 'L',
      inline: true,
      enabledDates: enabledDatesArrayMoment,
      defaultDate: enabledDatesArrayFormatted[0],
      icons: {
        previous: 'icon-chevron-left',
        next: 'icon-chevron-right',
      }
    });
  }
});
