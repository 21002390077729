$(function() {
  if ($('.checkout-wrapper').length > 0) {
    handleChangePaymentType($('.checkout-wrapper'));
  }
});

function handleChangePaymentType(el) {
  const checkboxes = $('input[type="radio"]');
  const total = $('#checkoutTotal');

  checkboxes.on('keypress click', function(e) {
    if (e.which === 13 || e.type === 'click') {
      var subscriptionType = document.querySelector('input[name="paymentType"]:checked').dataset.subscriptionType;
      if (subscriptionType === 'month') {
        $(el).find('#month-membership-js').css('display', 'flex');
        $(el).find('#year-membership-js').css('display', 'none');
        $(el).find('#membership-discount-js').css('display', 'none');
        total.html('£96.00');
      } else {
        $(el).find('#month-membership-js').css('display', 'none');
        $(el).find('#year-membership-js').css('display', 'flex');
        $(el).find('#membership-discount-js').css('display', 'flex');
        total.html('£864.00');
      }
    }
  });
}
