require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("./bootstrap_custom.js")
require('general/navbar.js')
require('general/swipers.js')
require('general/links.js')
require('general/buttons.js')
require('general/alerts.js')
require('forms/form-validation.js')
require('forms/coupon.js')
require('forms/token.js')
require('forms/file-upload.js')
require('forms/custom-number-input.js')
require('checkout/payment_type.js')
require('bs-custom-file-input')
require("moment/locale/ja")
require("tempusdominus-bootstrap-4")
require('general/datepicker.js')