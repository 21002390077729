$(function() {
  if ($('.apply-token-js').length > 0) {
    handleTokenApplying($('.apply-token-js'));
  }
});

function handleTokenApplying(el) {
  const btnSubmit = $(el).find('.btn-apply-token-js');
  const btnCancel = $(el).find('.btn-cancel-js');
  const appliedTokenWrapper = $(el).find('.applied-token-js');

  btnSubmit.on('keypress click', function(e) {
    e.preventDefault();
    if (e.which === 13 || e.type === 'click') {
      appliedTokenWrapper.css('display','flex');
      $(this).hide();
    }
  });

  btnCancel.on('click', function(e) {
    e.preventDefault();
    appliedTokenWrapper.hide();
    btnSubmit.show();
  });
}
