// Bootstrap custom file input
// https://www.npmjs.com/package/bs-custom-file-input
//
import bsCustomFileInput from 'bs-custom-file-input'

$(function() {
  if ($('#dropArea').length > 0) {
    bsCustomFileInput.init()
  }
});
