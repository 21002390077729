/**
 * Navbar.
 *
 */

(function($) {
  $(function() {
    //Sticky navbar
    function stickyNav() {
      let scroll = $(window).scrollTop();
      let navbar = $('.navbar-secondary');
      let headerHeight = $('.header-page').height();

      // animate showing the navbar on scroll on each page
      if (scroll > headerHeight) {
        navbar.addClass('nav-scrolling');
      } else if (scroll <= headerHeight) {
        navbar.removeClass('nav-scrolling');
      }
    }

    //stickyNav();
    $(window).on("scroll", function() {
      stickyNav();
    });
  });
}(jQuery));