$(function() {
  if ($('.copy-to-clipboard-wrapper-js').length > 0) {
    $('.copy-to-clipboard-wrapper-js').each(handleCopyToClipboard);
  }
});

function addRemoveClass(el, delay) {
  delay = delay ? delay : 2000; // milliseconds
  let innerText = $(el).find('span');
  $(el).addClass('copied');
  innerText.toggleClass('sr-only');
  let temp = setInterval(function() {
    $(el).removeClass('copied');
    innerText.toggleClass('sr-only');
    clearInterval(temp);
  }, delay);
};

function handleCopyToClipboard(el) {
  let wrapper = $(this),
    input = wrapper.find('.copy-to-clipboard-input-js'),
    btn = wrapper.find('.copy-clipboard-btn-js');

  btn.on('click', function() {
    input.select();
    document.execCommand('copy');
    addRemoveClass($(this), 1000);
    return false;
  });
}
