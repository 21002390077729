$(function() {
  if ($('#customNumberInput').length > 0) {
    $('#customNumberInput').each(handleCustomNumberInput);
  }
});

function handleCustomNumberInput() {

  let inputWrapper = $(this),
    input = inputWrapper.find('input[type="number"]'),
    btnUp = inputWrapper.find('.quantity-up'),
    btnDown = inputWrapper.find('.quantity-down'),
    min = input.attr('min'),
    max = input.attr('max');

  btnUp.on('click', function() {
    let oldValue = parseFloat(input.val());
    if (oldValue >= max) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue + 1;
    }
    inputWrapper.find('input').val(newVal);
    inputWrapper.find('input').trigger('change');
    if (btnDown.hasClass('disabled')) {
      btnDown.removeClass('disabled');
    }
  });

  btnDown.on('click', function() {
    let oldValue = parseFloat(input.val());
    if (oldValue <= min) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue - 1;
    }
    inputWrapper.find('input').val(newVal);
    inputWrapper.find('input').trigger('change');
  });
};
